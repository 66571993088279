import React, {useEffect, useState} from "react";
import {get, post, useMarketingOA} from "../../MarketingOAProvider";
import {FormProvider, useForm} from "react-hook-form";
import {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "moment/locale/pt";
import pt from "date-fns/locale/pt";
import Loading from "../../components/Loading";
import sliceIntoChunks from "../../functions/splitIntoChunks";

// CSS Files
import "./styles.less";
import InputModulo from "../../components/InputModulo";
import Collapsible from "react-collapsible";

registerLocale("pt", pt);
moment.locale("pt");

const Configuracao = (props) => {
   const [config, setConfig] = useState(null);
   const [utilizadores, setUtilizadores] = useState(null);
   const [seccoes, setSeccoes] = useState(null);


   useEffect(() => {
      get("/config/utilizadores").then(setUtilizadores);
      get("/config").then(setConfig);
      get(`/edicoes/seccoes`).then(setSeccoes);
   }, []);

   const marketingOA = useMarketingOA();
   const formConfig = useForm();

   useEffect(() => {
      formConfig.reset(config);
   }, [config])

   const guardar = async (showPopup = true, validate = false) => {
      let promise = post(`/config/atualizar`, formConfig.getValues());

      if (showPopup)
         marketingOA.showAlert({
            text: "Alterações guardadas com sucesso",
            buttons: true,
            showCancelButton: false,
            confirmButtonText: "OK",
            donePromise: promise
         });
      try {
         await promise;
         marketingOA.reloadConfig();
      } catch (e) {
         if (showPopup)
            marketingOA.showAlert({
               text: e.response.data.message,
               buttons: true,
               showCancelButton: false,
               confirmButtonText: "OK"
            });
         else
            throw e;
      }
   }

   const atualizarUtilizadores = () => get("/config/utilizadores").then(setUtilizadores);

   return <Loading deps={[config, utilizadores, seccoes]} className={"Configuracao"} children={() => <>
      <div>
         <h1 style={{marginBottom: 30}}>Configuração geral</h1>
         <FormProvider {...formConfig}>
            <InputModulo title={"Texto"} description={"texto a percorrer o topo da plataforma"} name={"marquee"} maxLength={200}/>
         </FormProvider>
         <div className={"d-flex modulo-actions"}>
            <p className={"modulo-action serif italic"} style={{margin: 0}} onClick={guardar}>gravar</p>
         </div>
         <h1 style={{marginBottom: 30, marginTop: 60}}>Utilizadores</h1>
         {utilizadores.map(utilizador => <FormularioUtilizador key={utilizador.id} utilizador={utilizador} seccoes={seccoes} atualizar={atualizarUtilizadores}/>)}
         {!utilizadores.find(u => u.novo) && <div className={"d-flex modulo-actions"}>
            <p className={"modulo-action serif italic"} style={{margin: 0, marginBottom: 40, marginTop: 10}} onClick={() => {
               setUtilizadores(utilizadores.concat({novo: true, tipo_utilizador: "redactor", password: (Math.random() + 1).toString(36).substring(2)}))
            }}>criar utilizador</p>
         </div>}
      </div>
   </>}/>
}

function FormularioUtilizador({utilizador, seccoes, atualizar}) {
   const marketingOA = useMarketingOA();
   const [open, setOpen] = useState(!!utilizador.novo);
   const form = useForm({
      defaultValues: {
         ...utilizador,
         seccoes: utilizador.seccoes?.map(s => "" + s.id)
      }
   });

   const tipo_utilizador = form.watch("tipo_utilizador");

   const guardar = async () => {
      let valores = form.getValues()
      let promise;
      if (utilizador.novo)
         promise = post(`/config/utilizadores/criar`, valores);
      else
         promise = post(`/config/utilizadores/${utilizador.id}/editar`, valores);

      marketingOA.showAlert({
         text: utilizador.novo ? <>Utilizador criado com sucesso. Guarde as credenciais para futuros acessos:<br/>e-mail: {valores.email}<br/>password: {valores.password}</> : "Alterações guardadas com sucesso",
         buttons: true,
         showCancelButton: false,
         confirmButtonText: "OK",
         donePromise: promise
      });

      try {
         await promise;
         setOpen(false);
         atualizar && atualizar();
      } catch (e) {
         marketingOA.showAlert({
            text: e.response.data.message,
            buttons: true,
            showCancelButton: false,
            confirmButtonText: "OK"
         });
      }
   }

   const apagar = async () => {
      const confirmar = async () => {
         let promise = post(`/config/utilizadores/${utilizador.id}/apagar`);

         marketingOA.showAlert({
            text: "Utilizador apagado com sucesso",
            buttons: true,
            showCancelButton: false,
            confirmButtonText: "OK",
            donePromise: promise
         });

         try {
            await promise;
            setOpen(false);
            atualizar && atualizar();
         } catch (e) {
            marketingOA.showAlert({
               text: e.response.data.message,
               buttons: true,
               showCancelButton: false,
               confirmButtonText: "OK"
            });
         }
      }

      marketingOA.showAlert({
         text: "Tem a certeza que pretende apagar o utilizador " + utilizador.nome + "?",
         buttons: true,
         closeOnConfirm: false,
         onConfirm: confirmar
      });
   }

   return <Collapsible
      triggerClassName={"header_utilizador line d-flex justify-content-between"}
      triggerOpenedClassName={"header_utilizador line d-flex justify-content-between"}
      trigger={<>
         <div>
            <h3 className={"small light"}>{utilizador.novo ? "Novo utilizador" : utilizador.nome}</h3>
            <div><span style={{textTransform: "capitalize"}}>{utilizador.tipo_utilizador}</span>{utilizador.tipo_utilizador === "redactor" && <> · {utilizador.seccoes?.map(s => s.designacao_curta).join(", ")}</>}</div>
         </div>
         <span className={"close-icon"}/>
      </>}
      open={open}
      onOpening={() => setOpen(true)}
      onClosing={() => setOpen(false)}>
      <div className={"d-flex"}>
         <h3 className={"small light hide-mobile"} style={{opacity: 0}}>{utilizador.novo ? "Novo utilizador" : utilizador.nome}</h3>
         <div className={"flex-1"}>
            <div className={"formulario_utilizador"}>
               <FormProvider {...form}>
                  <p className="serif" style={{marginBottom: 2}}><i>Tipo</i></p>
                  <InputModulo name={"tipo_utilizador"} inputType={"select"} inputConfig={{
                     options: [
                        {value: "editor", label: "Editor"},
                        {value: "revisor", label: "Revisor"},
                        {value: "redactor", label: "Redactor"}
                     ]
                  }}/>
                  <p className="serif"><i>Nome</i></p>
                  <InputModulo name={"nome"}/>
                  <p className="serif"><i>E-mail</i></p>
                  <InputModulo name={"email"}/>
                  <p className="serif"><i>Password{utilizador.novo ? null : <>(vazia para manter)</>}</i></p>
                  <InputModulo name={"password"} type={"password"}/>
                  {tipo_utilizador === "redactor" && seccoes && <div className={"seccoes"}>
                     <p className={"serif"}><i>Seccões:</i></p>
                     {sliceIntoChunks(seccoes, 3).map(c => <div>
                        {c.map(({designacao_curta, id}) => <>
                           <label>
                              {id === 0 ? <strong>{designacao_curta}</strong> : designacao_curta}
                              <input className={"checkbox"} type={"checkbox"} {...form.register("seccoes[]", {
                                 required: "Escolha pelo menos uma seccção da newsletter"
                              })} value={id}/>
                              <span/>
                           </label>
                        </>)}
                     </div>)}
                  </div>}
                  <div className={"d-flex modulo-actions justify-content-end"}>
                     {utilizador.novo ? <p className={"modulo-action serif italic"} onClick={atualizar}>cancelar</p> :
                        <p className={"modulo-action serif italic"} style={{color: "#c30000"}} onClick={apagar}>apagar</p>}
                     <p className={"modulo-action serif italic"} onClick={guardar}>{utilizador.novo ? "criar" : "guardar"}</p>
                  </div>
               </FormProvider>
            </div>
         </div>
      </div>
   </Collapsible>;
}

export default Configuracao;
