import React from "react";
import {useMarketingOA} from "../../MarketingOAProvider";
import {Link, useLocation} from "react-router-dom";
import If from "../If";
import Marquee from "react-fast-marquee";

// Images
import SmallLogo from "../../assets/logo_compacto.svg";
import LogoNA from "../../assets/logo_NA.svg";

// CSS Files
import "./styles.less";

const Header = (props) => {
   const marketingOA = useMarketingOA();
   const location = useLocation();

   return <>
      {marketingOA.config.marquee && <Marquee gradient={false} className={"Header_roll"}><p>{marketingOA.config.marquee}</p></Marquee>}
      <div className={"Header flex-space-between"}>
         <If show={location.pathname !== "/"}>
            <Link to={marketingOA.utilizador ? "/" : "#"}>
               <img src={LogoNA} className={"logo-na"}/>
            </Link>
         </If>
         <div style={{marginLeft: "auto"}}>
            <img src={SmallLogo}/>
         </div>
      </div>
   </>;
}

export default Header;
