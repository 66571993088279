import React from "react";

// CSS Files
import "./styles.less";

const InputEdicao = (props) => {
   return <div className={"InputEdicao"}>
      <div className={"input-container"}>
         <div>
            <h1><i>{props.label}</i></h1>
            <p>{props.tooltip}</p>
         </div>

         <div className={"options-container " + (props.inputType)}>
            {props.children}
         </div>
      </div>
      <p className={"error serif"}>{props.error?.message}</p>
      <div className={"line"}></div>
   </div>
}

export default InputEdicao;