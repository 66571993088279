import React from "react";

const If = ({show, children}) => {
   if (!Array.isArray(show))
      show = [show];
   if (show.length > 0 && show.reduce((a, b) => a ? b : false, true))
      return children;
   return null;
}

export default If;