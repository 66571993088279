import React from "react";
import {useMarketingOA} from "../../MarketingOAProvider";
import Loading from "../Loading";
import moment from "moment";

// CSS Files
import "./styles.less";

const IntroInfo = ({children, ...props}) => {
   let marketingOA = useMarketingOA();

   return <div className={"IntroInfo"}>
      <div className={"intro-message"}>
         {children}
      </div>
      <Loading deps={[props.dias]} small children={() => <>
         <div className={"next-ed-deadline text-right"}>
            <div className={"d-flex align-items-end justify-content-end"}>
               <h1 className={"days-left"}>{Math.max(moment(props.dias).diff(moment(), "days"), 0)}</h1>
               <p className={"serif"}><i>dias</i></p>
            </div>
            {props.textDias}
         </div>
      </>}/>
   </div>
}

export default IntroInfo;