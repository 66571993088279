import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import Loading from "./components/Loading";
import {serialize} from 'object-to-formdata';
import If from "./components/If";
import {useLocation} from "react-router-dom";

const axios_instance = axios.create({
   baseURL: process.env.REACT_APP_API_URL,
   withCredentials: true
});

const MarketingOAContext = React.createContext({});

const get = async (url, data, config) => {
   return (await axios_instance.get(url, {params: data, ...config})).data;
};

const post = async (url, data) => {
   let formData = serialize(data, {indices: true, dotsForObjectNotation: true})
   return (await axios_instance.post(url, formData)).data;
};

const MarketingOAProvider = (props) => {
   const [utilizador, setUtilizador] = useState(null);
   const [alert, setAlert] = useState({show: false});
   const alertIsShowing = useRef(false);
   const [loading, setLoading] = useState(false);
   const [moduloSchema, setModuloSchema] = useState(null);
   const [config, setConfig] = useState({});

   const location = useLocation();
   const isOnResetPasswordPage = location.pathname.startsWith('/nova_password/');

   useEffect(() => {
      get(`/config`).then(setConfig)
      get("/modulo/schema").then(setModuloSchema);
      if(!isOnResetPasswordPage) {
         get("/autenticacao/info").then(setUtilizador).catch(() => setUtilizador(false));
      }
   }, []);

   const reloadConfig = () => get(`/config`).then(setConfig);

   const login = async (dados) => {
      setLoading(true);
      try {
         let utilizador = await post("/autenticacao/login", dados);
         setUtilizador(utilizador);
         setLoading(false);
      } catch (e) {
         setLoading(false);
         throw e;
      }
   };

   const logout = async () => {
      await post("/autenticacao/logout");
      setUtilizador(false);
   };

   const showAlert = (config) => {
      const aux = (show, loading) => {
         let key = alertIsShowing.current || Date.now();
         setAlert({
            key,
            show: show,
            text: config.text,
            buttons: config.buttons,
            showCloseButton: config.showCloseButton === undefined ? true : config.showCloseButton,
            showCancelButton: config.showCancelButton === undefined ? true : config.showCancelButton,
            confirmButtonText: config.confirmButtonText || "Confirmar",
            cancelButtonText: config.cancelButtonText || "Cancelar",
            onConfirm,
            onCancel,
            close,
            hasLoading: !!config.donePromise,
            loading
         });
         alertIsShowing.current = key;
      }

      const close = () => {
         alertIsShowing.current = false;
         aux(false);
      }

      const onConfirm = async () => {
         if (config.onConfirm) await config.onConfirm();
         if (config.closeOnConfirm === undefined || config.closeOnConfirm) close();
      };

      const onCancel = async () => {
         if (config.onCancel) await config.onCancel();
         close();
      };

      aux(true, !!config.donePromise);

      if (config.donePromise) {
         config.donePromise.then(() => {
            aux(true, false);
         }).catch((e) => {
            console.log(e);
         });
      }
   }

   return <MarketingOAContext.Provider value={{login, logout, utilizador, setLoading, moduloSchema, showAlert, config, reloadConfig}}>
      {props.children}
      <div className={"alert-popup" + (alert.show ? " show" : "")}>
         <Loading key={alert.key} deps={[!alert.loading]} small>
            <If show={alert.showCloseButton}><span className={"close"} onClick={alert.close}/></If>
            <p className={"serif text-center"}><i>{alert.text}</i></p>
            <If show={alert.buttons}>
               <div className={"buttons"}>
                  <a onClick={alert.onConfirm}>{alert.confirmButtonText}</a>
                  {alert.showCancelButton && <a onClick={alert.onCancel}>{alert.cancelButtonText}</a>}
               </div>
            </If>
         </Loading>
      </div>
      {loading && <div className={"loading-overlay"}>
         <Loading deps={[null]} noDelay/>
      </div>}
   </MarketingOAContext.Provider>
}

const useMarketingOA = () => React.useContext(MarketingOAContext);

export default MarketingOAProvider;
export {useMarketingOA, get, post};