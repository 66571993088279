import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Collapsible from "react-collapsible";
import {get, useMarketingOA} from "../../MarketingOAProvider";
import moment from "moment";
import EditarModulo from "./EditarModulo";
import EditarInformacoes from "./EditarInformacoes";
import If from "../../components/If";

// React Components
import IntroInfo from "../../components/IntroInfo";
import Loading from "../../components/Loading";

// CSS Files
import "./styles.less";

const CriarEdicao = (props) => {
   const marketingOA = useMarketingOA();

   let {id} = useParams();
   let [edicao, setEdicao] = useState(null);
   let [modulosAbertos, setModulosAbertos] = useState([]);

   let [modoRedactor, setModoRedactor] = useState(false);

   let tipo_utilizador = marketingOA.utilizador.tipo_utilizador;
   if (modoRedactor && marketingOA.utilizador?.acesso_funcionalidades.includes("edicao.editar"))
      tipo_utilizador = "redactor";

   const estadoModulo = {
      CRIADO: {
         description: {
            editor: <>Criado</>,
            revisor: <>Criado</>,
            redactor: <>Submissão pendente</>
         },
         viewed_by: ["redactor"]
      },
      REVISAO_PENDENTE: {
         description: {
            editor: <>Revisão pendente</>,
            revisor: <>Revisão pendente</>,
            redactor: <>Informações submetidas</>
         },
         viewed_by: ["editor", "revisor", "redactor"]
      },
      APROVACAO_PENDENTE: {
         description: {
            editor: <>Aprovação pendente</>,
            revisor: <>Aprovação pendente</>,
            redactor: <>Aprovação pendente</>
         },
         viewed_by: ["redactor"]
      },
      AJUSTES_PENDENTES: {
         description: {
            editor: <>Ajustes pendentes</>,
            revisor: <>Ajustes pendentes</>,
            redactor: <>Pendente de ajustes finais</>
         },
         viewed_by: ["editor", "revisor"]
      },
      TERMINADO: {
         description: {
            editor: <>Terminado</>,
            revisor: <>Terminado</>,
            redactor: <>Terminado</>
         },
         viewed_by: ["editor", "revisor", "redactor"]
      }
   };

   const obterEdicao = async () => {
      setEdicao(await get(`/edicoes/${id}/detalhes`, {modoRedactor: modoRedactor ? "1" : undefined}));
   }

   useEffect(() => {
      setEdicao(null);
      obterEdicao();
   }, [modoRedactor]);

   useEffect(() => {
      setModulosAbertos([]);
   }, [edicao]);

   let mensagem;
   switch (marketingOA.utilizador.tipo_utilizador) {
      case "editor":
         mensagem = <a className="link no-br" onClick={() => marketingOA.showAlert({
            text: <>
               Um editor é um colaborador da N—A afecto à Direcção de Comunicação Nacional. A sua
               função é definir o calendário para cada edição, sendo responsável por todas as fases do
               processo, desde a sua criação até ao seu envio. O editor pode ainda, em caso de necessidade,
               trabalhar em modo revisor, o que lhe permite substituir-se quer aos redactores, quer aos
               revisores. Um Editor pode criar novas N—A, bem como editar e enviar todas aquelas que ainda
               estão abertas para edição.
            </>,
            buttons: true,
            showCancelButton: false,
            confirmButtonText: "Entendi",
         })}>Editor</a>;
         break;
      case "revisor":
         mensagem = <a className="link no-br" onClick={() => marketingOA.showAlert({
            text: <>
               Um Revisor é um colaborador da N—A afecto à Direcção de Comunicação Nacional. A sua
               função é criar os módulos da N—A a partir dos pedidos das secções regionais. O revisor pode
               ainda substituir-se aos redactores das secções regionais e preencher ele próprio os campos
               com a informação necessária para a criação dos módulos de informação. Um Revisor só pode
               trabalhar sobre as N—A já criadas e abertas para edição.
            </>,
            buttons: true,
            showCancelButton: false,
            confirmButtonText: "Entendi",
         })}>Revisor</a>;
         break;
      case "redactor":
         mensagem = <a className="link no-br" onClick={() => marketingOA.showAlert({
            text: <>
               Um Redactor é um colaborador da N—A afecto a uma determinada secção regional. A sua
               função é enviar a informação necessária para a criação dos módulos de informação, e validar
               os módulos depois de devidamente editados pela equipa de comunicação. Sempre que houver
               necessidade de interação da sua parte, o redactor será avisado por email. Um Redactor só
               pode trabalhar sobre as N—A já criadas e disponíveis para edição.
            </>,
            buttons: true,
            showCancelButton: false,
            confirmButtonText: "Entendi",
         })}>Redactor</a>;
         break;
   }

   return <Loading deps={[edicao]} className={"EditarEdicao"} children={() => <>
      <IntroInfo dias={new Date(edicao.data_prevista_envio)}
                 textDias={<>até ao fecho <br/> da newsletter</>}>
         <p className={"serif"}><i>Bem-vindo/a, {marketingOA.utilizador.nome}.</i></p>
         <p className={"serif"}><i>
            Obrigado pelo teu contributo. Estás a editar a newsletter <strong>{edicao.titulo}</strong>. Lembra-te que tens até 3 notícias
            e/ou 9 breves, num máximo de 3 módulos, para submeter e/ou rever. Verifica sempre se não
            tens nenhuma tarefa por completar (!) e os dias disponíveis até ao fecho da próxima edição.
            Para dúvidas, questões e mais informações contacta-nos através do email <a className="link no-br" href={"mailto:comunicacao@ordemdosarquitectos.org?subject=Apoio%20técnico%20N—A"}>comunicacao@ordemdosarquitectos.org</a>
         </i></p>
         <p className={"serif"} style={{marginTop: 10}}><i>Estás a trabalhar em modo {mensagem}</i></p>
         <If show={marketingOA.utilizador?.acesso_funcionalidades.includes("edicao.editar")}>
            <label className={"toggle"}>
               <p className="italic serif">Modo de {marketingOA.utilizador.tipo_utilizador === "editor" ? "revisor" : "redactor"}</p>
               <input className={"checkbox"} type={"checkbox"} checked={modoRedactor} onChange={(e) => setModoRedactor(!modoRedactor)}/>
               <span/>
            </label>
         </If>
      </IntroInfo>
      <If show={tipo_utilizador === 'editor'}>
         <div>
            <div className={"modulos"}>
               <Collapsible
                  triggerClassName={"header_modulo line d-flex align-items-start"}
                  triggerOpenedClassName={"header_modulo line d-flex align-items-start"}
                  trigger={<>
                     <div className={"d-flex flex-1"}>
                        <div className={"info-modulo"}>
                           <h1 className={"bold"}><i>Informações</i></h1>
                           <p className={"small serif"}>&nbsp;</p>
                        </div>
                     </div>
                     <span className={"close-icon"}/>
                  </>}>
                  <EditarInformacoes edicao={edicao}/>
               </Collapsible>
            </div>
         </div>
      </If>
      {edicao.seccoes_edicao.map(seccao => <div>
         <h2 className={"text-right section-title"}>{seccao.designacao}</h2>
         <div className={"modulos"}>
            {seccao.modulos.map(modulo => {
               let can_view = estadoModulo[modulo.estado].viewed_by.includes(tipo_utilizador);
               return <Collapsible
                  onOpening={() => setModulosAbertos([...modulosAbertos, modulo.id])}
                  onClosing={() => {
                     let aux = [...modulosAbertos];
                     let index = aux.indexOf(modulo.id);
                     if (index !== -1)
                        aux.splice(index, 1);
                     setModulosAbertos(aux);
                  }}
                  open={modulosAbertos.includes(modulo.id)}
                  triggerClassName={"header_modulo line d-flex align-items-start"}
                  triggerOpenedClassName={"header_modulo line d-flex align-items-start"}
                  triggerDisabled={!can_view}
                  trigger={<>
                     <div className={"d-flex flex-1"}>
                        <div className={"info-modulo"}>
                           <h1 className={"bold"}><i>{modulo.designacao}</i></h1>
                           <p className={"small serif"}>Envio previsto a <br/>{moment(edicao.data_prevista_envio).format("D/MM")}</p>
                        </div>
                        <div className={"status"}>
                           <h3 className={"small light"}>{estadoModulo[modulo.estado].description[tipo_utilizador]}</h3>
                           {modulo.utilizador_alteracao && <p className={"serif"}>por: {modulo.utilizador_alteracao}</p>}
                        </div>
                     </div>
                     {can_view && <span className={"close-icon"}/>}
                  </>}>
                  {can_view && modulosAbertos.includes(modulo.id) && <EditarModulo key={modulo.id + "_" + modoRedactor} id={modulo.id} modulo={modulo} recarregarEdicao={obterEdicao} aberto={modulosAbertos.includes(modulo.id)} modoRedactor={modoRedactor}/>}
               </Collapsible>
            })}
         </div>
      </div>)}
   </>}/>
}

export default CriarEdicao;
