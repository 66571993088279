import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from 'react-router-dom';
import MarketingOAProvider from "./MarketingOAProvider";
import Routes from "./Routes"

// React Components
import Header from "./components/Header";
import Footer from "./components/Footer";

// CSS Files
import './styles.less';

const App = () => {
   return <BrowserRouter>
      <MarketingOAProvider>
         <div className={"container"}>
            <Header/>
            <div style={{flex: 1}}>
               <Routes/>
            </div>
            <Footer/>
         </div>
      </MarketingOAProvider>
   </BrowserRouter>
};

ReactDOM.render(<App/>, document.getElementById("MarketingOA"));
