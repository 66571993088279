import React, {useState} from 'react';
import {useParams, useHistory} from "react-router-dom";
import {post, useMarketingOA} from "../../MarketingOAProvider";
import "./styles.less";
import showpass from "../../assets/showpass.svg";
import LogoNA from "../../assets/logo_NA.svg";


const RecuperarPassword = () => {

   const [password, setPassword] = useState('');
   const [confirmPassword, setConfirmPassword] = useState('');
   const [showPassword, setShowPassword] = useState(false);
   const [showConfirmPassword, setShowConfirmPassword] = useState(false);
   const marketingOA = useMarketingOA();
   const history = useHistory();
   let {token} = useParams();
   const handleSubmit = async event => {
      event.preventDefault();

      if (password.length < 6) {
         marketingOA.showAlert({
            text: "A password deve ter pelo menos 6 caracteres",
            buttons: true,
            showCancelButton: false,
            confirmButtonText: "OK",
         });
         return;
      }
      if (password !== confirmPassword) {
         marketingOA.showAlert({
            text: "As passwords não coincidem!",
            buttons: true,
            showCancelButton: false,
            confirmButtonText: "OK",
         });
      } else {
         try {
            const response = await post("/autenticacao/nova_password", {
               token: token,
               password: password
            });
            marketingOA.showAlert({
               text: "Palavra-passe alterada com sucesso!",
               buttons: true,
               showCancelButton: false,
               confirmButtonText: "OK",
               onConfirm: () => window.location.href = '/'
            });
         } catch (error) {
            marketingOA.showAlert({
               text: error.response.data.message,
               buttons: true,
               showCancelButton: false,
               confirmButtonText: "OK",
            });
         }
      }
   };


   return (
      <div className={"RecuperarPassword"}>
         <img src={LogoNA}/>
         <form onSubmit={handleSubmit}>
            <div className={"campos"}>
               <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  placeholder={"palavra-passe"}
                  onChange={e => setPassword(e.target.value)}
               />

               <button
                  type="button"
                  className={"mostrarPassword"}
                  onMouseDown={() => {setShowPassword(true)}}
                  onMouseUp={() => {setShowPassword(false)}}
               >
              <img src={showpass} alt="showpass"/>
               </button>
            </div>

            <div className={"campos"}>
               <input
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  placeholder={"confirmar palavra-passe"}
                  onChange={e => setConfirmPassword(e.target.value)}
               />

               <button
                  className={"mostrarPassword"}
                  type="button"
                  onMouseDown={() => {setShowConfirmPassword(true)}}
                  onMouseUp={() => {setShowConfirmPassword(false)}}>
                  <img src={showpass} alt="showpass"/>
               </button>
            </div>

            <button className={"submeter"} type="submit">alterar password</button>
         </form>
      </div>
   );
}

export default RecuperarPassword;

