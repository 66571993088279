import React from "react";
import {Link, useLocation} from "react-router-dom";
import {useMarketingOA} from "../../MarketingOAProvider";

// CSS Files
import "./styles.less";

const Footer = (props) => {
   const marketingOA = useMarketingOA();
   const location = useLocation();

   if (location.pathname === "/")
      return null;

   return <div className={"Footer"}>
      <div className={"line"}/>

      <div className={"links-container"}>
         <div>
            {marketingOA.utilizador?.acesso_funcionalidades?.includes("config.atualizar") && <Link to={"/configuracao"}>CONFIGURAÇÃO</Link>}
         </div>
         <div>
            {marketingOA.utilizador && <div className={"link"} onClick={() => marketingOA.logout()}>TERMINAR SESSÃO</div>}
            {!marketingOA.utilizador && <div className={"link"} onClick={() => window.history.back()}>VOLTAR</div>}
         </div>
      </div>
   </div>
}

export default Footer;
