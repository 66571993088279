import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {useMarketingOA} from "./MarketingOAProvider";

// Pages
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import CriarEdicao from "./pages/CriarEdicao";
import EditarEdicao from "./pages/EditarEdicao";
import GerirSubscricao from "./pages/GerirSubscricao";
import Configuracao from "./pages/Configuracao";
import RecuperarPassword from "./pages/RecuperacaoPassword";

const Routes = (props) => {
   let marketingOA = useMarketingOA();

   let routes = [
      <Route exact path={"/subscricao/:hash"} component={GerirSubscricao}/>,
      <Route exact path="/nova_password/:token" component={RecuperarPassword} />,
   ];

   if (marketingOA.utilizador !== null) {
      if (marketingOA.utilizador?.acesso_funcionalidades?.includes("edicao.adicionar"))
         routes.push(<Route path={"/newsletter/criar"} component={CriarEdicao}/>);

      if (marketingOA.utilizador?.acesso_funcionalidades?.includes("config.atualizar"))
         routes.push(<Route path={"/configuracao"} component={Configuracao}/>);

      if (marketingOA.utilizador)
         routes.push(...[
            <Route path={"/newsletter/:id(\\d+)"} component={EditarEdicao}/>,
            <Route path={"/dashboard"} component={Dashboard}/>,
            <Redirect to={"/dashboard"}/>
         ]);
      else
         routes.push(...[
            <Route exact path={"/"} component={Login}/>,
            <Redirect to={"/"}/>
         ]);
   }

   return <Switch>
      {routes}
   </Switch>;
}

export default Routes;