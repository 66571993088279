import React, {useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {post, useMarketingOA} from "../../MarketingOAProvider";

// Images
import LogoNA from "../../assets/logo_NA.svg";

// CSS Files
import "./styles.less";

const toastOptions = {
   hideAfter: 5,
   position: 'top-right'
}

const Login = (props) => {
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [recuperarPassword, setRecuperarPassword] = useState(false);

   const history = useHistory();
   const marketingOA = useMarketingOA();

   const handleChange = e => {
      if (e.target.name === "email")
         setEmail(e.target.value);

      if (e.target.name === "password")
         setPassword(e.target.value);
   }

   const handleSubmit = async e => {
      e.preventDefault();

      let data = {email, password};

      if (recuperarPassword) {
         let promise = post("/autenticacao/recuperacao_password", data);
         marketingOA.showAlert({
            text: "Pedido submetido com sucesso. Caso o utilizador exista, receberá um e-mail com um código para definir a nova password.",
            buttons: true,
            showCancelButton: false,
            confirmButtonText: "OK",
            donePromise: promise
         });
         try {
            await promise;
            setRecuperarPassword(false);
         } catch (e) {
            marketingOA.showAlert({
               text: e.response.data.message,
               buttons: true,
               showCancelButton: false,
               confirmButtonText: "OK"
            });
         }
         return;
      }

      try {
         await marketingOA.login(data);
         history.push("/dashboard");
      } catch (error) {
         if (error.response.data.code === 401) {
            marketingOA.showAlert({
               text: error.response.data.message,
               buttons: true,
               showCancelButton: false,
               confirmButtonText: "OK"
            });
         }
      }
   }

   return <div className={"Login"}>
      <img src={LogoNA}/>
      <form onSubmit={handleSubmit}>
         {recuperarPassword && <p>Introduza o seu endereço de e-mail para continuar:</p>}
         <input type="text" name={"email"} value={email} onChange={handleChange} placeholder={"utilizador"} className={"serif"}/>
         {recuperarPassword && <button type="submit" className={"change_password"}>alterar password</button>}
         {!recuperarPassword && <input type="password" name={"password"} value={password} onChange={handleChange} placeholder={"palavra-passe"} className={"password serif"}/>}
         <input type="submit" className={"submit-login"}/>

         <div className={"extra-links"}>
            <Link className={"serif"} onClick={() => setRecuperarPassword(!recuperarPassword)}>{recuperarPassword ? "iniciar sessão" : "recuperar password"}</Link>
            <a className={"serif"} href={"mailto:newsletter@arquitectos.pt?subject=Pedido%20de%20criação%20de%20utilizador%20N—A"}>criar utilizador</a>
            <Link className={"serif"} onClick={handleSubmit}>entrar no editor</Link>
         </div>
      </form>
   </div>
}

export default Login;
