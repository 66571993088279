import React, {useEffect, useState} from "react";
import {post, useMarketingOA} from "../../../MarketingOAProvider";
import {FormProvider, useForm} from "react-hook-form";
import ItemsCarousel from "react-items-carousel";
import PreviewPDF from "../../../components/PreviewPDF";
import InputModulo from "../../../components/InputModulo";
import FormEditarModulo from "../FormEditarModulo";
import {Element, scroller} from "react-scroll";

// CSS Files
import "./styles.less";

const WizardRevisao = ({modulo, formMethods, schema, ...props}) => {
   const [passo, setPasso] = useState(props.finalizar ? 2 : 0);
   const [template, setTemplate] = useState(modulo.template);
   const marketingOA = useMarketingOA();
   const formNotasRevisao = useForm({
      defaultValues: {notas_seccao: modulo.notas_seccao || "A seccção regional não adicionou nenhuma nota"}
   });

   useEffect(() => {
      scroller.scrollTo('wizard', {
         duration: 1000,
         delay: 0,
         smooth: true,
         offset: -50
      });
   }, [passo]);

   const passos = [1, 2, 3];

   const formRedactor = useForm({
      defaultValues: modulo.informacoes.SUBMETIDO
   })

   const copiar = () => {
      marketingOA.showAlert({
         text: "Copiar as informações preenchidas pela secção irá destruir a informação preenchida na coluna da direita. Confirma?",
         buttons: true,
         onConfirm: () => {
            formMethods.reset(modulo.informacoes.SUBMETIDO);
         }
      });
   }

   let voltar = async () => {
      setPasso(passo - 1);
   }

   let avancar = async () => {
      switch (passo) {
         case 0:
            try {
               let resultado = await props.guardar(false, true);
               formMethods.reset(resultado.RASCUNHO_EDITOR.dados);
            } catch (e) {
               marketingOA.showAlert({
                  text: e.response ? e.response.data.message : e.message,
                  buttons: true,
                  showCancelButton: false,
                  confirmButtonText: "OK"
               });
               return;
            }
            break;
         case 1:
            break;
         case 2:
            break;

      }
      setPasso(passo + 1);
   }

   let terminar = () => {

      let continuar = async () => {
         let promise;
         let message = '';
         let url = `/modulo/${modulo.id}/`;
         let body = {};

         if (props.finalizar) {
            url += 'finalizar';
            message = "O módulo foi finalizado com sucesso.";
            body.template = template;
         } else {
            url += 'submeter';
            message = "A revisão foi submetida com sucesso e será revista em breve pela seccão regional";
            body.template = template;
         }
         promise = post(url, body);

         marketingOA.showAlert({
            text: message,
            buttons: true,
            showCancelButton: false,
            confirmButtonText: "FECHAR",
            donePromise: promise,
            onConfirm: props.recarregarEdicao
         });

         try {
            await promise;
         } catch (e) {
            marketingOA.showAlert({
               text: e.response?.data?.message || e.message,
               buttons: true,
               showCancelButton: false,
               confirmButtonText: "OK"
            });
         }
      }

      marketingOA.showAlert({
         text: props.finalizar ? "Tem a certeza que pretende finalizar o módulo?" :
            "Pretende submeter a revisão? A secção regional irá ver as informações submetidas.",
         buttons: true,
         closeOnConfirm: false,
         onConfirm: continuar
      });
   }

   let tipoModulo = formMethods.getValues("tipo");

   return <div className={"WizardRevisao"}>
      <Element name={'wizard'}/>
      <div className={"passos"}>
         {passos.map((p, idx) => <>
            {idx > 0 && "|"}
            <p className={"serif italic" + (idx === passo ? " active" : "")}>{p}</p>
         </>)}
      </div>
      <ItemsCarousel
         activeItemIndex={passo}
         numberOfCards={1}
         gutter={0}
         disableSwipe={true}
         classes={{wrapper: "items-carousel-wrapper", itemWrapper: "items-carousel-item-wrapper"}}
      >
         <div className={"d-flex"}>
            <div className={"column"}>
               <h3 className={"small light form-header"}>Submeter</h3>
               <FormEditarModulo formMethods={formRedactor} register={formRedactor.register} schema={schema} disabled formOptions={{showNumbers: false}}/>
               <div className={"modulo-actions"}>
                  <div className={"modulo-action bold"} onClick={copiar}>copiar</div>
               </div>
            </div>
            <div className={"column"}>
               <h3 className={"small light form-header"}>Rever</h3>
               <FormEditarModulo formMethods={formMethods} register={formMethods.register} schema={schema} formOptions={{showNumbers: false}}/>
               <div className={"d-flex modulo-actions align-items-center"}>
                  <div className={"modulo-action bold"} onClick={avancar}>avançar ></div>
               </div>
            </div>
         </div>
         <div className={"d-flex"}>
            <div className={"column hide-mobile"}>
               <h3 className={"small light form-header"}>Submeter</h3>
               <FormEditarModulo formMethods={formMethods} register={formMethods.register} schema={schema} disabled formOptions={{showNumbers: false}}/>
               <div className={"d-flex modulo-actions align-items-center"}>
                  <p className={"modulo-action serif italic"} onClick={voltar}>voltar</p>
               </div>
            </div>
            <div className={"column"}>
               <h3 className={"small light form-header"}>Rever</h3>
               {(props.finalizar ? passo === 1 : passo >= 1) && <>
                  {(tipoModulo !== "breves" ? ["a", "b", "c"] : ["b"]).map(t => <div onClick={() => setTemplate(`modulo_${t}`)} style={{cursor: "pointer", marginBottom: 30}}>
                     <h3 className={"small template-header"}>Módulo {t.toUpperCase()}</h3>
                     <PreviewPDF url={`/modulo/${modulo.id}/preview/modulo_${t}?asPDF=1&t=${props.dataModificado}`} active={template === `modulo_${t}`}/>
                  </div>)}
               </>}
               <div className={"d-flex modulo-actions align-items-center"}>
                  <div className={"modulo-action bold"} onClick={avancar}>avançar</div>
               </div>
            </div>
         </div>
         <div className={"d-flex"}>
            <div className={"column"}>
               <h3 className={"small light form-header"}>Revisão</h3>
               {(props.finalizar || passo >= 2) && <PreviewPDF url={`/modulo/${modulo.id}/preview/${template}?asPDF=1&t=${props.dataModificado}`} active/>}
               <div className={"d-flex modulo-actions align-items-center"}>
                  <p className={"modulo-action serif italic"} onClick={voltar}>voltar</p>
                  <div className={"modulo-action bold"} onClick={terminar}>terminar</div>
               </div>
            </div>
            <div className={"column notas_seccao"}>
               <h3 className={"small light form-header"}>Notas da secção regional</h3>
               {modulo.estado === "AJUSTES_PENDENTES" ? <div style={{pointerEvents: "none"}}><FormProvider {...formNotasRevisao}>
                     <InputModulo title={"Notas para revisão:"} name={"notas_seccao"} maxLength={500}/>
                  </FormProvider></div> :
                  <p className={"serif italic"}>Os comentários da seccção regional serão apresentados aqui.</p>
               }
            </div>
         </div>
      </ItemsCarousel>
   </div>
}

export default WizardRevisao;