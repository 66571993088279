import React, {useCallback, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {get, post, useMarketingOA} from "../../MarketingOAProvider";
import sliceIntoChunks from "../../functions/splitIntoChunks";
import {useForm} from "react-hook-form";
import usePrevious from "../../functions/usePrevious";

// CSS Files
import "./styles.less";
import Loading from "../../components/Loading";

const GerirSubscricao = (props) => {
   let {hash} = useParams();
   let [informacoes, setInformacoes] = useState(null);
   let [seccoes, setSeccoes] = useState(null);
   let {showAlert} = useMarketingOA();

   const {register, watch, setValue} = useForm();
   const values = watch();
   const seccoesSelecionadasAntes = usePrevious(values.seccao);

   const obterInfo = async () => {
      try {
         let info = await get(`/subscricao/detalhes/${hash}`);
         setInformacoes(info);
      } catch (e) {
         showAlert({
            text: e.response.data.message,
            buttons: true,
            showCancelButton: false,
            confirmButtonText: "OK"
         });
      }
   }

   const obterSeccoes = async () => {
      setSeccoes([...await get(`/edicoes/seccoes`), {id: 0, designacao_curta: "Todas"}]);
   }

   useEffect(() => {
      obterInfo();
      obterSeccoes();
   }, []);

   useEffect(() => {
      if (!values.seccao || !seccoesSelecionadasAntes || values.seccao.length === seccoes.length + 1)
         return;
      // Caso o "todas" estivesse desativado e agora ativado, selecionar todas as regiões
      if (!seccoesSelecionadasAntes.includes('0') && values.seccao.includes('0'))
         setValue("seccao", [...seccoes.map(s => "" + s.id), "0"]);
      // Caso contrário e caso o "todas" estivesse selecionado, remover o "todas"
      else if (values.seccao.includes('0')) {
         let index = values.seccao.indexOf('0');
         let aux = [...values.seccao];
         if (index !== -1)
            aux.splice(index, 1);
         setValue("seccao", aux);
      }
   }, [values.seccao]);


   useEffect(() => {
      if (seccoes && informacoes) {
         setValue("seccao", informacoes.seccoes.map(s => "" + s.id));
         setValue("subscrito", informacoes.subscrito);
      }
   }, [seccoes, informacoes]);

   const guardar = useCallback(() => {
      showAlert({
         text: "Informações de subscrição alteradas com sucesso. Poderá ser necessário aguardar uns minutos para as alterações serem refletidas.",
         buttons: true,
         showCancelButton: false,
         confirmButtonText: "OK",
         donePromise: post(`/subscricao/alterar/${informacoes.hash}`, {
            seccoes: values.seccao,
            subscrito: values.subscrito
         })
      });
   }, [values, informacoes])

   return <div className={"GerirSubscricao"}>
      <hr style={{marginBottom: 40}}/>
      <Loading deps={[seccoes, informacoes]}>
         <h1 style={{marginBottom: 20, marginTop: 0}}>Preferências de Subscrição</h1>
         {informacoes?.nome && <p className={"serif"}><i>{informacoes?.nome}</i></p>}
         <p className={"serif"}><i>{informacoes?.email}</i></p>
         <div className={"seccoes"}>
            <p className={"serif"}><i>Estado da subscrição:</i></p>
            <div>
               <label>
                  Subscrição ativada
                  <input className={"checkbox"} type={"checkbox"} {...register("subscrito")}/>
                  <span/>
               </label>
            </div>
         </div>
         <div className={"seccoes"}>
            <p className={"serif"}><i>Seccões:</i></p>
            {sliceIntoChunks(seccoes || [], 3).map(c => <div>
               {c.map(({designacao_curta, id, nacional}, idx) => <>
                  <label style={{pointerEvents: nacional ? "none" : undefined}}>
                     {id === 0 ? <strong>{designacao_curta}</strong> : designacao_curta}
                     <input className={"checkbox"} type={"checkbox"} value={id} {...register("seccao")}/>
                     <span/>
                  </label>
               </>)}
            </div>)}
         </div>
         <p className={"guardar"} onClick={guardar}>guardar ></p>
      </Loading>
   </div>
}

export default GerirSubscricao;