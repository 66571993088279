import React, {useEffect, useState} from "react";
import InputModulo from "../../../components/InputModulo";
import {FormProvider, useFormContext} from "react-hook-form";

// CSS Files
import "./styles.less";

import plus from "../../../assets/plus.svg";

const ArrayInput = ({field, options, name}) => {
   const [itemCount, setItemCount] = useState(field.minItems || field.itemsMultiple || 1);
   const {watch} = useFormContext();
   const value = watch(name);

   useEffect(() => {
      if (value && itemCount < value.length)
         setItemCount(value.length);
   }, [value?.length]);

   return <div className={"ArrayInput input-mod-container" + (field.compact ? " compact" : "")}>
      {field.title && <h3 className={"small input-block-title light"}>{field.title} <p className={"serif italic"}>{field.description}</p></h3>}
      {options.showNumbers !== false && <h3 className={"input-number small light" + (field.title ? " with-title" : "")}>{field.number}</h3>}
      {new Array(itemCount).fill(0).map((_, idx) => {
         let n = `${name}[${idx}]`;
         let title = "";
         if (field?.items.title)
            title = field?.items.title.replace(/%\w+%/g, function (all) {
               return {"%NUMBER%": idx + 1}[all] || all;
            });
         return <InputModulo {...field.items} key={n} name={n} title={title} formOptions={options}/>
      })}
      {(!field.maxItems || itemCount < field.maxItems) && <img className={"add-item"} src={plus} onClick={() => {
         setItemCount(itemCount + (field.itemsMultiple || 1));
      }}/>}
   </div>;
}

function gerarInputs(register, properties, prefix = null, options = {}) {
   return Object.keys(properties).map((name, key) => {
      let field = properties[name];
      if (field.hideType && options.type && field.hideType.includes(options.type))
         return null;
      if (prefix)
         name = `${prefix}.${name}`;
      if (field.type === 'object' && field.properties) {
         return <div key={name} className={"input-mod-container" + (field.compact ? " compact" : "")}>
            {options.showNumbers !== false && <h3 className={"input-number small light"}>{field.number}</h3>}
            <h3 className={"small input-block-title light"}>{field.title} <p className={"serif italic"}>{field.description}</p></h3>
            {gerarInputs(register, field.properties, name, options)}
         </div>;
      }
      if (field.type === 'array') {
         return <ArrayInput {...{options, field, name}}/>;
      }
      return <div className={"input-mod-container" + (field.compact ? " compact" : "")}>
         {options.showNumbers !== false && <h3 className={"input-number small light"}>{field.number}</h3>}
         <InputModulo {...field} name={name} formOptions={options}/>
      </div>;
   })
}

const FormEditarModulo = (props) => {
   let type = props.formMethods.watch("tipo");
   return <div className={"FormEditarModulo " + (props.disabled ? "disabled" : "")}>
      <FormProvider {...props.formMethods} >
         {gerarInputs(props.register, props.schema.properties, null, {...props.formOptions, type})}
      </FormProvider>
   </div>
};

export default FormEditarModulo;