import React, {useState} from "react";
import {Link} from "react-router-dom";
import moment from "moment";
import {post, useMarketingOA} from "../../../MarketingOAProvider";
import If from "../../../components/If";

// CSS Files
import "./styles.less";

const Edicao = (props) => {
   const marketingOA = useMarketingOA();
   let dataEdicao = moment(props.edicao.data_prevista_envio);
   let [terminada, setTerminada] = useState(!!props.edicao?.data_terminada);
   let [suspensa, setSuspensa] = useState(false);

   let finalizar = async () => {
      let continuar = async () => {
         let promise = post(`/edicoes/${props.edicao.id}/finalizar`);

         marketingOA.showAlert({
            text: "A edição foi finalizada com sucesso.",
            buttons: true,
            donePromise: promise
         });

         promise
            .then(_ => setTerminada(true))
            .catch(e => marketingOA.showAlert({
               text: e.response?.data?.message || e.message,
               buttons: true,
               showCancelButton: false,
               confirmButtonText: "OK"
            }))
      }

      marketingOA.showAlert({
         text: <>
            A edição será marcada como finalizada e será enviada para as listas de subscritores. Esta ação é irreversível, pretende continuar?<br/><br/>
            Pode previsualizar a newsletter <a className={"link"} target={"_blank"} href={`${process.env.REACT_APP_API_URL}/edicoes/${props.edicao.id}/preview`}>aqui</a>.
            {props.edicao?.acoes_pendentes > 0 && <><br/><br/><u>Nota</u>: Existem módulos com ações pendentes.</>}
         </>,
         buttons: true,
         closeOnConfirm: false,
         onConfirm: continuar
      });
   }

   let suspender = async () => {
      let continuar = async () => {
         let promise = post(`/edicoes/${props.edicao.id}/suspender`);

         promise.then(_ => setSuspensa(true))
            .catch(e => marketingOA.showAlert({
               text: e.response?.data?.message || e.message,
               buttons: true,
               showCancelButton: false,
               confirmButtonText: "OK"
            }))
      }

      marketingOA.showAlert({
         text: <>
            A edição será marcada como suspensa e será ocultada.<br/>Para voltar a ver clique em 'mostrar newsletters suspensas'.
         </>,
         buttons: true,
         onConfirm: continuar
      });
   }

   if (suspensa)
      return null;

   return <div className={"Edicao"}>
      <h3 className={"edition-type " + (terminada ? "finished" : "")}>
         {({AGENDA: "A", REGULAR: "N"})[props.edicao?.tipo]}—<span style={{color: props.edicao.cores}}>A</span>
      </h3>
      <div className={"edition-data" + (terminada ? " finished" : "")}>
         <div className={"d-flex flex-direction-row"}>
            <div>
               <p className={"serif edition-month"}><i>{dataEdicao.format("MMM")}</i></p>
               <p className={"serif"}>{dataEdicao.format("D/MM")}</p>
            </div>
            {!terminada && props.edicao?.acoes_pendentes > 0 && <h4 className={"has-actions"}>!</h4>}
         </div>
         <h3 className={"edition-title small"}>{props.edicao.titulo}</h3>
      </div>

      <div className={"edition-actions"}>
         {terminada ? <div>
            <i className={"serif closed-edition"}>Edição fechada</i>
            <a href={`${process.env.REACT_APP_API_URL}/edicoes/${props.edicao?.id}/preview`} target={"_blank"}>Ver newsletter</a>
         </div> : <div className={"open-edition"}>
            <Link className={"serif link"} style={{color: props.edicao.cores}} to={`/newsletter/${props.edicao.id}/editar`}>Editar</Link>
            <If show={marketingOA.utilizador?.acesso_funcionalidades.includes("edicao.finalizar")}>
               <a className={"serif link"} style={{color: props.edicao.cores}} onClick={finalizar}>Finalizar</a>
               {/*<a className={"serif link"} style={{color: props.edicao.cores}} onClick={suspender}>Suspender</a>*/}
            </If>
         </div>}
      </div>
   </div>
}

export default Edicao;