import React from "react";
import {FormProvider, useForm} from "react-hook-form";
import {post, useMarketingOA} from "../../../MarketingOAProvider";
import InputModulo from "../../../components/InputModulo";

// CSS Files
import "./styles.less";

const EditarInformacoes = ({edicao}) => {
   const marketingOA = useMarketingOA();
   const formDetalhes = useForm({
      defaultValues: {
         titulo: edicao.titulo,
         introducao: edicao.introducao
      }
   });

   const values = formDetalhes.watch();

   const guardar = async (showPopup = true, validate = false) => {
      if (validate) {
         let validacao = await formMethods.trigger();
         if (!validacao)
            throw {message: "Verifique o preenchimento dos campos."};
      }

      let promise = post(`/edicoes/${edicao.id}/atualizar`, values);

      if (showPopup)
         marketingOA.showAlert({
            text: "Alterações guardadas com sucesso",
            buttons: true,
            showCancelButton: false,
            confirmButtonText: "OK",
            donePromise: promise
         });

      try {
         await promise;
      } catch (e) {
         if (showPopup)
            marketingOA.showAlert({
               text: e.response.data.message,
               buttons: true,
               showCancelButton: false,
               confirmButtonText: "OK"
            });
         else
            throw e;
      }
   }

   return <div className={"EditarInformacoes d-flex"}>
      <div className={"column"}>
         <FormProvider {...formDetalhes}>
            <InputModulo title={"Título"} description={"título e assunto da newsletter"} name={"titulo"} maxLength={100}/>
            <InputModulo title={"Introdução"} description={"texto a colocar no cabeçalho do e-mail"} name={"introducao"} maxLength={300}/>
         </FormProvider>
         <div className={"d-flex modulo-actions align-items-center"}>
            <p className={"modulo-action serif italic"} onClick={guardar}>gravar</p>
         </div>
      </div>
   </div>
}

export default EditarInformacoes;