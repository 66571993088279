import React, {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {post, useMarketingOA} from "../../../MarketingOAProvider";
import InputModulo from "../../../components/InputModulo";
import If from "../../../components/If";
import PreviewPDF from "../../../components/PreviewPDF";
import FormEditarModulo from "../FormEditarModulo";
import WizardRevisao from "../WizardRevisao";
import usePrevious from "../../../functions/usePrevious";
import {scroller} from "react-scroll";

// CSS Files
import "./styles.less";

const EditarModulo = ({modulo, ...props}) => {
   const marketingOA = useMarketingOA();
   const {estado, informacoes} = modulo;

   let tipo_utilizador = marketingOA.utilizador.tipo_utilizador;
   let editor = marketingOA.utilizador?.acesso_funcionalidades.includes("edicao.editar");
   if (props.modoRedactor && editor) {
      tipo_utilizador = "redactor";
      editor = false;
   }

   let valoresIniciais = informacoes?.REVISTO || informacoes?.RASCUNHO_EDITOR || informacoes?.SUBMETIDO || informacoes?.RASCUNHO;
   if (estado === "REVISAO_PENDENTE" && editor)
      valoresIniciais = informacoes?.RASCUNHO_EDITOR;
   const formMethods = useForm({defaultValues: valoresIniciais});
   const formRever = useForm();
   const [dataModificado, setDataModificado] = useState(Date.now());

   const {register, watch} = formMethods;
   const {watch: watchFormRever} = formRever;

   const values = watch();
   const notas_seccao = watchFormRever();

   const schema = marketingOA.moduloSchema;

   const inputsErro = Object.keys(formMethods.formState.errors);
   const errosAntes = usePrevious(inputsErro);

   useEffect(() => {
      if (inputsErro.length === 0)
         return;
      let first = inputsErro[0];
      if (errosAntes.includes(first))
         return;
      scroller.scrollTo('input.' + first, {
         duration: 1000,
         delay: 0,
         smooth: true,
         offset: -50
      });
   }, [inputsErro]);

   const guardar = async (showPopup = true, validate = false) => {
      if (validate) {
         let validacao = await formMethods.trigger();
         if (!validacao)
            throw {message: "Verifique o preenchimento dos campos."};
      }

      let promise = post(`/modulo/${props.id}/guardar${props.modoRedactor ? "?modoRedactor=1" : ""}`, values);

      if (showPopup)
         marketingOA.showAlert({
            text: "Alterações guardadas com sucesso",
            buttons: true,
            showCancelButton: false,
            confirmButtonText: "OK",
            donePromise: promise
         });

      try {
         let result = await promise;
         setDataModificado(Date.now());
         return result;
      } catch (e) {
         if (showPopup)
            marketingOA.showAlert({
               text: e.response.data.message,
               buttons: true,
               showCancelButton: false,
               confirmButtonText: "OK"
            });
         else
            throw e;
      }
   }

   const submeter = (confirm_message) => {
      if (typeof confirm_message !== "string")
         confirm_message = null;

      let continuar = async () => {
         let promise = new Promise(async (resolve, reject) => {
            try {
               await guardar(false, true);
               await post(`/modulo/${props.id}/submeter${props.modoRedactor ? "?modoRedactor=1" : ""}`);
               resolve();
            } catch (e) {
               reject(e);
            }
         })

         marketingOA.showAlert({
            text: "Informações submetidas com sucesso.",
            buttons: true,
            showCancelButton: false,
            confirmButtonText: "FECHAR",
            donePromise: promise,
            onConfirm: props.recarregarEdicao
         });

         promise.catch(e => marketingOA.showAlert({
            text: e.response?.data?.message || e.message,
            buttons: true,
            showCancelButton: false,
            confirmButtonText: "OK"
         }));
      }

      marketingOA.showAlert({
         text: confirm_message || "As informações serão submetidas de forma irreversível. Tem a certeza que pretende avançar?",
         buttons: true,
         closeOnConfirm: false,
         onConfirm: continuar
      });
   }

   let submeterNotas = () => {
      let continuar = async () => {
         let promise = post(`/modulo/${modulo.id}/submeter_notas`, notas_seccao);

         marketingOA.showAlert({
            text: "Notas submetidas para ajustes com sucesso. O editor irá fazer os ajustes necessários e finalizar o módulo",
            buttons: true,
            showCancelButton: false,
            confirmButtonText: "FECHAR",
            donePromise: promise,
            onConfirm: props.recarregarEdicao
         });

         promise.catch(e => marketingOA.showAlert({
            text: e.response?.data?.message || e.message,
            buttons: true,
            showCancelButton: false,
            confirmButtonText: "OK"
         }))
      }

      marketingOA.showAlert({
         text: "As notas preenchidas irão ser encaminhadas para que o editor possa finalizar o módulo.",
         buttons: true,
         closeOnConfirm: false,
         onConfirm: continuar
      });
   }

   return <div className={"EditarModulo d-flex"}>
      <If show={estado === "CRIADO"}>
         <div className={"column"}>
            <h3 className={"small light form-header"}>Submeter</h3>
            <FormEditarModulo formMethods={formMethods} register={register} schema={schema}/>
            <div className={"d-flex modulo-actions align-items-center"}>
               <p className={"modulo-action serif italic"} onClick={guardar}>gravar</p>
               <p className={"modulo-action bolder"} onClick={submeter}>submeter</p>
            </div>
         </div>
         <div className={"column hide-mobile"}>
            <h3 className={"small light form-header"}>Rever</h3>
            <p className={"serif italic disabled"}>edição em curso.</p>
            <div className={"d-flex modulo-actions align-items-center"}>
               <p className={"modulo-action serif italic disabled"} onClick={guardar}>gravar</p>
               <p className={"modulo-action bolder disabled"} onClick={submeter}>submeter</p>
            </div>
         </div>
      </If>

      <If show={[
         estado === "REVISAO_PENDENTE",
         tipo_utilizador === "redactor"
      ]}>
         <div className={"column"}>
            <h3 className={"small light form-header"}>Informações enviadas</h3>
            <FormEditarModulo formMethods={formMethods} register={register} schema={schema} disabled/>
         </div>
         <div className={"column"}>
            <h3 className={"small light form-header"}>Rever</h3>
            <p className={"serif italic disabled"}>edição em curso.</p>
            <div className={"d-flex modulo-actions align-items-center"}>
               <p className={"modulo-action serif italic disabled"} onClick={guardar}>gravar</p>
               <p className={"modulo-action bolder disabled"} onClick={submeter}>submeter</p>
            </div>
         </div>
      </If>

      <If show={[
         ["REVISAO_PENDENTE", "AJUSTES_PENDENTES"].includes(estado),
         editor
      ]}>
         {props.aberto && <WizardRevisao {...{formMethods, schema, modulo, guardar, dataModificado}} finalizar={estado === "AJUSTES_PENDENTES"} recarregarEdicao={props.recarregarEdicao}/>}
      </If>


      <If show={[
         estado === "APROVACAO_PENDENTE",
         tipo_utilizador === "redactor"
      ]}>
         <div className={"column"}>
            <h3 className={"small light form-header"}>Informações</h3>
            <FormEditarModulo formMethods={formMethods} register={register} schema={schema} disabled/>
         </div>
         <div className={"column"}>
            <h3 className={"small light"}>Rever</h3>
            <PreviewPDF url={`/modulo/${modulo.id}/preview/${modulo.template}?asPDF=1`} active/>
            <div className={"notas"}>
               <FormProvider {...formRever}>
                  <InputModulo name={"notas_seccao"} title={"Notas para revisão:"} maxLength={500} isNotas/>
               </FormProvider>
            </div>
            <div className={"d-flex modulo-actions align-items-center"}>
               <p className={"modulo-action bolder"} onClick={submeterNotas}>submeter</p>
            </div>
         </div>
      </If>
      <If show={[
         estado === "TERMINADO",
         tipo_utilizador !== "editor"
      ]}>
         <div className={"column"}>
            <h3 className={"small light"}>Pré-visualizar</h3>
            <div style={{maxWidth: 600, marginLeft: "auto"}}>
               <PreviewPDF url={`/modulo/${modulo.id}/preview/${modulo.template}?asPDF=1&finalizado=1`} active/>
            </div>
         </div>
      </If>
      <If show={[
         estado === "TERMINADO",
         tipo_utilizador === "editor"
      ]}>
         {props.aberto && <WizardRevisao {...{formMethods, schema, modulo, guardar, dataModificado}} finalizar={estado === "TERMINADO"} recarregarEdicao={props.recarregarEdicao}/>}
      </If>

   </div>
}

export default EditarModulo;