import React from "react";
import ReactCSSTransitionReplace from "react-css-transition-replace";

// CSS Files
import "./styles.less";

const LoadingImg = <svg x="0px" y="0px" width="100%" height="100%" viewBox="0 0 300.3 213" style={{"enableBackground": "new 0 0 300.3 213"}}>
   <path style={{"stroke": "#000000", "strokeWidth": 45, "strokeMiterlimit": 10, "fill": "transparent"}}
         className="st0 svg-elem-1" d="M276.1,208.8L276.9,24L249,24.2l-87.7,107.4c-17.6,19.7-31.9,59.6-75.7,59.6c-34.2,0-62.9-26-62.9-63.7c0-40.8,33.3-62.9,62.9-62.9c46.5,0,83.3,48.7,51,100.4"/>
</svg>;

const Loading = ({deps, children, className, noDelay, small}) => {
   let content = deps.filter(d => d).length === deps.length ? <div>{typeof children === 'function' ? children() : children}</div> : <div key={"loading"} className={"loading-svg" + (noDelay ? "" : " delay") + (small ? " small" : "")}>{LoadingImg}</div>;
   return <div className={"Loading " + className}>
      <ReactCSSTransitionReplace
         transitionName="cross-fade"
         transitionEnterTimeout={500}
         transitionLeaveTimeout={500}
         component={"div"}
         childComponent={"div"}>
         {content}
      </ReactCSSTransitionReplace>
   </div>
}

export default Loading;
