import React from "react";
import Edicao from "../Edicao";
import {Link} from "react-router-dom";
import {useMarketingOA} from "../../../MarketingOAProvider";
import If from "../../../components/If";

// CSS Files
import "./styles.less";
import Collapsible from "react-collapsible";

const Edicoes = (props) => {
   const marketingOA = useMarketingOA();

   return <div className={"Edicoes"}>
      <Collapsible
         open={props.idx === 0}
         triggerClassName={"header_ano"}
         triggerOpenedClassName={"header_ano"}
         trigger={<>
            <div className={"edition-collapsible"}>
               <div className={"line"}/>
               <div className={"arrow-down"}/>
            </div>
            <div className={"d-flex flex-1"}>
               <h1><i>{props.ano}</i></h1>
            </div>
            <span className={"close-icon"}/>
         </>}>
         <div className={"editions-list"}>
            <div className={"d-flex flex-1"}>
               <h1><i>{props.ano}</i></h1>
               <h4 className={"editions-marker"}>Newsletters</h4>
               <If show={[marketingOA.utilizador?.acesso_funcionalidades.includes("edicao.adicionar")]}>
                  <Link className={"create-edition hide-desktop" + (props.idx > 0 ? " hidden" : "")}
                        to={"/newsletter/criar"}>
                     <h2>+ </h2>
                     <span>criar</span>
                  </Link>
               </If>
            </div>
            <div className={"editions"}>
               <If show={[
                  props.idx === 0,
                  marketingOA.utilizador?.acesso_funcionalidades.includes("edicao.adicionar")
               ]}>
                  <Link className={"create-edition hide-mobile d-flex align-items-center"}
                        to={"/newsletter/criar"}>
                     <h2>+ </h2>
                     <span>criar</span>
                  </Link>
               </If>
               {props.dados.map((edicao, key) => {
                  return <Edicao key={key} edicao={edicao}/>
               })}
            </div>
         </div>
      </Collapsible>
   </div>
}

export default Edicoes;