import React, {useCallback, useEffect, useState} from "react";
import {get, post, useMarketingOA} from "../../MarketingOAProvider";
import InputEdicao from "../../components/InputEdicao";
import {Controller, useForm} from "react-hook-form";
import ReactDatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "moment/locale/pt";
import pt from "date-fns/locale/pt";
import Loading from "../../components/Loading";
import {useHistory} from "react-router-dom";
import sliceIntoChunks from "../../functions/splitIntoChunks";
import usePrevious from "../../functions/usePrevious";

// CSS Files
import "./styles.less";

registerLocale("pt", pt);
moment.locale("pt");

function capitalizeFirstLetter(string) {
   return string.charAt(0).toUpperCase() + string.slice(1);
}

const CriarEdicao = (props) => {
   const [cores, setCores] = useState(null);
   const [seccoes, setSeccoes] = useState(null);
   const navigation = useHistory();

   const marketingOA = useMarketingOA();
   const {register, control, watch, setValue, formState, trigger} = useForm({
      defaultValues: {tipo: "REGULAR"}
   });
   const values = watch();
   const seccoesSelecionadas = watch("seccao");
   const seccoesSelecionadasAntes = usePrevious(seccoesSelecionadas);
   const errors = formState?.errors;

   const onSubmit = useCallback(async (data) => {
      let validacao = await trigger();
      if (!validacao)
         throw {message: "Verifique o preenchimento dos campos."};

      data.titulo = `Newsletter ${capitalizeFirstLetter(moment(data.data_prevista_envio).format("MMM YYYY"))}`;
      data.introducao = "";
      data.seccao = data.seccao.filter(s => s !== '0');

      marketingOA.setLoading(true);
      try {
         let edicao = await post("/edicoes/criar", data);
         navigation.push(`/newsletter/${edicao.id}/editar`);
      } catch (e) {
         marketingOA.showAlert({
            text: e.response.data.message,
            buttons: true,
            showCancelButton: false,
            confirmButtonText: "OK"
         });
      }
      marketingOA.setLoading(false);
   }, []);

   const obterCores = async () => {
      setCores(await get(`/edicoes/cores`));
   }

   const obterSeccoes = async () => {
      setSeccoes([...await get(`/edicoes/seccoes`), {id: 0, designacao_curta: "Todas"}]);
   }

   useEffect(() => {
      obterCores();
      obterSeccoes();
   }, []);

   useEffect(() => {
      if (!seccoesSelecionadas || !seccoesSelecionadasAntes || seccoesSelecionadas.length === seccoes.length + 1)
         return;
      // Caso o "todas" estivesse desativado e agora ativado, selecionar todas as regiões
      if (!seccoesSelecionadasAntes.includes('0') && seccoesSelecionadas.includes('0'))
         setValue("seccao", [...seccoes.map(s => "" + s.id), "0"]);
      // Caso contrário e caso o "todas" estivesse selecionado, remover o "todas"
      else if (seccoesSelecionadas.includes('0')) {
         let index = seccoesSelecionadas.indexOf('0');
         let aux = [...seccoesSelecionadas];
         if (index !== -1)
            aux.splice(index, 1);
         setValue("seccao", aux);
      }
   }, [seccoesSelecionadas]);

   useEffect(() => {
      if (seccoes)
         setValue("seccao", seccoes.map(s => "" + s.id));
   }, [seccoes]);

   return <Loading deps={[cores, seccoes]} className={"CriarEdicao"} children={() => <>
      <div>
         <InputEdicao label={"Newsletter"} tooltip={"a editar"} inputType={"tipo"} error={errors?.tipo}>
            <label className={""}>
               <input type="radio" {...register("tipo", {
                  required: "Escolha a Newsletter a editar",
                  validate: (value) => value === "REGULAR" ? true : "Neste momento só é possível criar uma newsletter regular"
               })} value={"REGULAR"}/>
               <h3>N—<span style={{color: values.cores}}>A</span></h3>
               <p>Newsletter Regular</p>
            </label>
            <label style={{pointerEvents: "none"}}>
               <input type="radio" {...register("tipo")} value={"AGENDA"}/>
               <h3>A—<span style={{color: values.cores}}>A</span></h3>
               <p>Agenda Mensal<br/>
                  <small>(Brevemente)</small>
               </p>
            </label>
            <label style={{pointerEvents: "none"}}>
               <input type="radio" {...register("tipo")} value={"AGENDA"}/>
               <h3>L—<span style={{color: values.cores}}>A</span></h3>
               <p>Legislação<br/>
                  <small>(Brevemente)</small>
               </p>
            </label>
         </InputEdicao>
         <InputEdicao label={"Cor"} tooltip={"secundária"} inputType={"cor"} error={errors?.cores}>
            {cores.map(c => <label>
               <input type="radio" {...register("cores", {
                  required: "Escolha a cor do tema"
               })} value={c.codigo}/>
               <span style={{backgroundColor: c.codigo, opacity: values.cores ? undefined : 1}}/>
            </label>)}
         </InputEdicao>
         <InputEdicao label={"Datas"} inputType={"datas"} tooltip={values.data_prevista_envio ? <>Envio previsto a <br/>{moment(values.data_prevista_envio).format("D/MM")}</> : null} error={errors?.data_limite_informacao || errors?.data_limite_revisao || errors?.data_prevista_envio}>
            <label className={"datepicker-container"}
                   onClick={e => e.target !== e.currentTarget && e.preventDefault()}>
               <p>Limite para envio de informação</p>
               <div>
                  <Controller
                     control={control}
                     name="data_limite_informacao"
                     rules={{
                        required: "Indique a data-limite para envio de informações"
                     }}
                     render={({field}) => (
                        <ReactDatePicker
                           placeholderText="dd-mm-aaaa"
                           locale="pt"
                           dateFormat="dd-MM-yyyy"
                           minDate={new Date()}
                           onChange={(e) => field.onChange(e)}
                           selected={field.value}
                        />
                     )}
                  />
               </div>
            </label>

            <label className={"datepicker-container"}
                   onClick={e => e.target !== e.currentTarget && e.preventDefault()}>
               <p>Limite para fecho da revisão</p>
               <div>
                  <Controller
                     control={control}
                     name="data_limite_revisao"
                     rules={{
                        required: "Indique a data-limite para revisão das informações"
                     }}
                     render={({field}) => (
                        <ReactDatePicker
                           placeholderText="dd-mm-aaaa"
                           locale="pt"
                           dateFormat="dd-MM-yyyy"
                           minDate={values.data_limite_informacao}
                           onChange={(e) => field.onChange(e)}
                           selected={field.value}
                        />
                     )}
                  />
               </div>
            </label>

            <label className={"datepicker-container"}
                   onClick={e => e.target !== e.currentTarget && e.preventDefault()}>
               <p>Data prevista de envio</p>
               <div>
                  <Controller
                     control={control}
                     name="data_prevista_envio"
                     rules={{
                        required: "Indique a data prevista para envio da newsletter"
                     }}
                     render={({field}) => (
                        <ReactDatePicker
                           placeholderText="dd-mm-aaaa"
                           locale="pt"
                           dateFormat="dd-MM-yyyy"
                           minDate={values.data_limite_revisao}
                           onChange={(e) => field.onChange(e)}
                           selected={field.value}
                        />
                     )}
                  />
               </div>
            </label>
         </InputEdicao>
         <InputEdicao label={"Secções"} inputType={"seccoes"} tooltip={values.data_prevista_envio ? <>Envio previsto a <br/>{moment(values.data_prevista_envio).format("D/MM")}</> : null} error={errors?.seccao}>
            {sliceIntoChunks(seccoes, 3).map(c => <div>
               {c.map(({designacao_curta, id, nacional}, idx) => <>
                  <label>
                     {id === 0 ? <strong>{designacao_curta}</strong> : designacao_curta}
                     <input className={"checkbox"} type={"checkbox"} {...register("seccao[]", {
                        required: "Escolha pelo menos uma seccção da newsletter"
                     })} value={id}/>
                     <span/>
                  </label>
               </>)}
            </div>)}
         </InputEdicao>
      </div>
      <div style={{color: values.cores}}
           className={"create-edition-btn"}
           onClick={() => onSubmit(values)}>
         criar >
      </div>
   </>}/>
}

export default CriarEdicao;
