import React, {useEffect, useMemo, useState} from "react";
import {get, useMarketingOA} from "../../MarketingOAProvider";
import Edicoes from "./Edicoes";
import IntroInfo from "../../components/IntroInfo";
import Loading from "../../components/Loading";

// CSS Files
import "./styles.less";

const Dashboard = () => {
   const [edicoes, setEdicoes] = useState(null);
   const [mostrar_suspensas, setMostrarSuspensas] = useState(false);

   const marketingOA = useMarketingOA();

   const getEditions = async () => {
      let edicoes = await get("/edicoes/listar", {mostrar_suspensas});
      setEdicoes(edicoes);
   }

   const edicoesOrdenadas = useMemo(() => {
      if (!edicoes)
         return {};

      let edicoesOrdenadas = {};
      edicoes.forEach(edicao => {
         let ano = String(new Date(edicao.data_prevista_envio).getFullYear());
         if (!edicoesOrdenadas[ano])
            edicoesOrdenadas[ano] = [];
         edicoesOrdenadas[ano].push(edicao);
      })

      return edicoesOrdenadas;
   }, [edicoes]);

   useEffect(() => {
      getEditions();
   }, []);

   let anos = Object.keys(edicoesOrdenadas || {});
   anos.sort((a, b) => b - a);

   return <div className={"Dashboard"}>
      <IntroInfo
         dias={edicoes ? Math.max(...edicoes.map(e => new Date(e.data_prevista_envio))) : null}
         textDias={<>até ao fecho <br/> da próxima newsletter</>}>
         <p className={"serif"}><i>Bem-vindo/a, {marketingOA.utilizador.nome}.</i></p>
         <p className={"serif"}><i>Obrigado pelo teu contributo. Clica em editar uma newsletter ativa para submeter informação
            ou verificar a sua redação. Lembra-te sempre de verificar se não tens nenhuma tarefa por
            completar (!) e os dias disponíveis até ao fecho da próxima edição. Para dúvidas, questões e
            mais informações contacta-nos através do email <a className="link no-br" href={"mailto:comunicacao@ordemdosarquitectos.org?subject=Apoio%20técnico%20N—A"}>comunicacao@ordemdosarquitectos.org</a>
         </i></p>
         {/*<If show={marketingOA.utilizador.tipo_utilizador === "editor"}>
            <label className={"toggle"}>
               <p className="italic serif">Mostrar newsletters suspensas</p>
               <input className={"checkbox"} type={"checkbox"} value={mostrar_suspensas} onChange={_ => setMostrarSuspensas(!mostrar_suspensas)}/>
               <span/>
            </label>
         </If>*/}
      </IntroInfo>
      <Loading deps={[edicoes]} children={() => <>
         {anos.map((ano, key) => {
            return <Edicoes idx={key} key={key} ano={ano} dados={edicoesOrdenadas[ano]}/>
         })}
      </>}/>
   </div>
}

export default Dashboard;