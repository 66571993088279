import React, {useEffect, useState} from "react";
import {Controller, useFormContext} from "react-hook-form";
import If from "../If";
import ReactDatePicker from "react-datepicker";
import {Element} from "react-scroll";

// CSS Files
import "./styles.less";

const ImageInput = ({props, value, change}) => {
   const [previewImagem, setPreviewImagem] = useState(null);
   let preview = previewImagem || (value?.filename ? `${process.env.REACT_APP_API_URL}/public/storage/${value.filename}` : null)

   useEffect(() => {
      if (value?.filename)
         setPreviewImagem(null);
   }, [value]);

   let onChange = (file) => {
      if (file && file.type.startsWith("image/")) {
         let reader = new FileReader();

         reader.addEventListener("load", function () {
            setPreviewImagem(this.result);
         }, false);

         reader.readAsDataURL(file);
      }

      change(file);
   }
   if (preview) {
      return <div className={"input-image d-flex"}>
         <div className={"img"} style={{backgroundImage: `url(${preview})`}}/>
         <label className={"flex-1"}>
            <p className={"serif italic"}><strong>editar</strong></p>
            <input type={"file"} onChange={(e) => onChange(e.target.files[0])} accept={props.contentMediaType}/>
         </label>
         <label style={{marginLeft: 5}} onClick={() => onChange(null)}>
            <p className={"serif italic"} style={{paddingLeft: 10, color: "black"}}><strong>x</strong></p>
         </label>
      </div>;
   }
   return <label className={"input-image"}>
      <p className={"serif italic"}>
         <strong className={"title"}>{(props.title || '')} </strong>
         {(props.description || '')}
      </p>
      <input type={"file"} onChange={(e) => onChange(e.target.files[0])} accept={props.contentMediaType}/>
   </label>;
}

const Breve = ({props, value, change}) => {
   value = value || {};

   let onChange = field => ({target: {value: newValue}}) => {
      let a = {...value};
      a[field] = newValue;
      change(a);
   }

   return <div className={"input-breve"}>
      <p className={"serif"}>
         <h3 className={"light small"}>{(props.title || '')} </h3>
         {(props.description || '')}
      </p>

      <TextArea {...props.properties.titulo} onChange={onChange("titulo")} value={value.titulo}/>
      <TextArea {...props.properties.texto} onChange={onChange("texto")} value={value.texto}/>
      <TextArea {...props.properties.link_externo} onChange={onChange("link_externo")} value={value.link_externo}/>
   </div>;
}

const TextArea = React.forwardRef(({value, onChange, ...props}, ref) => {
   const [placeholderHeight, setPlaceholderHeight] = useState(0);
   let height = Math.max(30 * Math.ceil((props.maxLength || 1) / 50), placeholderHeight);

   return <>
      <p ref={ref => ref && setPlaceholderHeight(ref.clientHeight)} className={"placeholder serif italic"} style={{opacity: !value ? 1 : 0}}>
         <strong className={"title"}>{(props.title || '')} </strong>
         {(props.description || '')}
      </p>
      <textarea ref={ref} onChange={onChange} value={value} style={{height: height}}/>
   </>
});

const DatePicker = ({value, onChange, ...props}) => {
   const [placeholderHeight, setPlaceholderHeight] = useState(0);
   let height = Math.max(30 * Math.ceil((props.maxLength || 1) / 50), placeholderHeight);

   let date = value && new Date(value);

   return <>
      <p ref={ref => ref && setPlaceholderHeight(ref.clientHeight)} className={"placeholder serif italic"} style={{opacity: !value ? 1 : 0}}>
         <strong className={"title"}>{(props.title || '')} </strong>
         {(props.description || '')}
      </p>
      <div style={{height}}>
         <ReactDatePicker
            locale="pt"
            dateFormat="dd/MM/yyyy"
            onChange={(e) => onChange(e)}
            selected={(!value || isNaN(date.getTime())) ? null : date}
         />
      </div>
   </>
};

const Select = ({value, change, props}) => {
   const [dropdownOpen, setDropdownOpen] = useState(false);

   let options = props?.inputConfig?.options
   if (!options) {
      options = props.enum.map(v => ({value: v, label: v}))
   }

   useEffect(() => {
      if (!value)
         change(options[0].value);
   });

   return <div className={"input-select"}>
      <p className={"serif"}>
         <h3 className={"light small"}>{(props.title || '')} </h3>
         {(props.description || '')}
      </p>
      <label className={dropdownOpen ? "open" : ""} onClick={() => setDropdownOpen(!dropdownOpen)}>
         {options.find(v => v.value === value)?.label}
      </label>
      {dropdownOpen && <div className={"backdrop"} onClick={() => setDropdownOpen(false)}/>}
      <div className={"dropdown" + (dropdownOpen ? " open" : "")}>
         {options.map(({value: v, label}) => <span onClick={() => {
            setDropdownOpen(false);
            change(v);
         }} key={v} className={v === value ? "active" : ""}>{label}</span>)}
      </div>
   </div>
};

const InputModulo = (props) => {
   const {control, trigger} = useFormContext();
   const ignoreKeywordsValidation = props.formOptions?.ignoreKeywordsValidation || [];
   return <>
      <Element name={'input.' + props.name}/>
      <Controller
         control={control}
         name={props.name}
         rules={{
            required: !ignoreKeywordsValidation.includes("minLength") ? !!props.minLength : undefined,
            maxLength: !ignoreKeywordsValidation.includes("maxLength") ? props.maxLength : undefined
         }}
         render={({field: {onChange, value, ref}, fieldState: {error}}) => {
            let change = (e) => {
               onChange(e);
               if (error)
                  trigger(props.name);
            };
            let input;
            switch (props.inputType) {
               case "image":
                  input = <ImageInput props={props} value={value} change={change}/>
                  break;
               case "datepicker":
                  input = <DatePicker {...props} value={value} onChange={change}/>
                  break;
               case "breve":
                  input = <Breve props={props} value={value} change={change}/>
                  break;
               case "select":
                  input = <Select props={props} value={value} change={change}/>
                  break;
               default:
                  input = <TextArea {...props} onChange={change} value={value} ref={ref}/>;
            }
            return <div className={"InputModulo" + (error ? " with-error" : "")}>
               {input}
               <If show={error?.type === "maxLength"}>
                  <p className={"serif error"}>+ {value?.length - props.maxLength}</p>
               </If>
               <If show={error?.type === "required"}>
                  <p className={"serif error"}>preenchimento obrigatório</p>
               </If>
            </div>
         }}
      />
   </>;
}

export default InputModulo;