import React, {useEffect, useState} from "react";
import * as PDFJS from "pdfjs-dist/legacy/build/pdf";
import {get} from "../../MarketingOAProvider";

import "./styles.less";

function cropImageFromCanvas(ctx) {
   let imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
   let data = imageData.data;

   let imgWidth = ctx.canvas.width;
   let imgHeight = ctx.canvas.height;

   let getRBG = function (x, y) {
      return {
         red: data[(ctx.canvas.width * y + x) * 4],
         green: data[(ctx.canvas.width * y + x) * 4 + 1],
         blue: data[(ctx.canvas.width * y + x) * 4 + 2]
      };
   };

   let isWhite = function (rgb) {
      return rgb.red === 255 && rgb.green === 255 && rgb.blue === 255;
   };

   let scanY = function (fromTop) {
      let offset = fromTop ? 1 : -1;
      for (let y = fromTop ? 0 : imgHeight - 1; fromTop ? (y < imgHeight) : (y > -1); y += offset)
         for (let x = 0; x < imgWidth; x++)
            if (!isWhite(getRBG(x, y)))
               return y;
      return null; // all image is white
   };

   let scanX = function (fromLeft) {
      let offset = fromLeft ? 1 : -1;
      for (let x = fromLeft ? 0 : imgWidth - 1; fromLeft ? (x < imgWidth) : (x > -1); x += offset)
         for (let y = 0; y < imgHeight; y++)
            if (!isWhite(getRBG(x, y)))
               return x;
      return null; // all image is white
   };

   let cropTop = scanY(true),
      cropBottom = scanY(false),
      cropLeft = scanX(true),
      cropRight = scanX(false);

   ctx.canvas.height = cropBottom;
   ctx.putImageData(imageData, 0, 0)
}

const PreviewPDF = ({url, active}) => {
   const [image, setImage] = useState(null);
   const [loading, setLoading] = useState(false);
   const [ref, setRef] = useState();

   useEffect(() => {
      if (!ref)
         return;

      let width = ref.clientWidth;
      width *= window.devicePixelRatio;

      (async () => {
         setLoading(true);
         PDFJS.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");
         let data = await get(url, {}, {responseType: 'arraybuffer'});
         let document = await PDFJS.getDocument({data}).promise;
         const page = await document.getPage(1);
         const viewport = page.getViewport({scale: 1});
         const canvas = window.document.createElement('canvas');
         const ctx = canvas.getContext('2d');
         canvas.width = width;
         canvas.height = (viewport.height / viewport.width) * width;
         let drawViewport = viewport.clone({scale: width / viewport.width});
         await page.render({canvasContext: ctx, viewport: drawViewport}).promise;
         cropImageFromCanvas(ctx);
         canvas.toBlob(async blob => {
            setImage({
               src: URL.createObjectURL(blob),
               paddingBottom: canvas.height * 100 / canvas.width,
               width: Math.floor(ref.clientWidth)
            });
            setLoading(false);
         });
      })();

   }, [ref, url]);

   return <div ref={setRef} className={"PreviewPDF " + (loading ? "loading" : "")}>
      <div className={"image"} style={{paddingBottom: (image ? image.paddingBottom : 50) + "%", backgroundImage: image?.src ? ("url(" + image?.src + ")") : undefined, opacity: active ? 1 : 0.5, backgroundSize: `${Math.floor(image?.width)}px auto`}}/>
      <div className={"loader"}/>
   </div>;
}

export default PreviewPDF;